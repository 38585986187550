
import Vuex from 'vuex'
import auth0 from 'auth0-js'
import router from './router'
import axios from 'axios';
// import config from './config';

const getDefaultValues = ()=> {
  return {
    userIsAuthorized:false,
    email:null,
    companyId:null,
    companyName:null,
    userId:null,
    roles:[],
    avatarUrl:'',
    auth0: new auth0.WebAuth({
      domain: process.env.VUE_APP_AUTH0_CONFIG_DOMAIN, 
      clientID: process.env.VUE_APP_AUTH0_CONFIG_CLIENTID,
      redirectUri: window.location.origin+ '/auth0callback',  
      responseType: process.env.VUE_APP_AUTH0_CONFIG_RESPONSETYPE,
      scope: process.env.VUE_APP_AUTH0_CONFIG_SCOPE,
    }), 
  }
}
export default new Vuex.Store({
  state: getDefaultValues(),
  computed:{
  },
  mutations: {
    setEmail(state,email){
      state.email =  email;
    },
    setCompanyName(state,companyName){
      state.companyName = companyName;
    },
    setComanyId(state,companyId){
      state.companyId = companyId;
    },
    setUserId(state,userId){
      state.userId = userId;
    },
    setRoles(state,roles){
      state.roles = roles;
    },
    setUserIsAuthenticated(state, replacement){
      state.userIsAuthorized = replacement;
    },
    setAvatar(state, replacement){
      state.avatarUrl = replacement
    }
  },
  actions: {
    auth0Login(context){
      console.log("in a store action named auth0Login");
      context.state.auth0.authorize()
    },
    auth0HandleAuthentication (context) {
      context.state.auth0.parseHash((err, authResult) => {
        console.log(authResult);
        if (authResult && authResult.accessToken && authResult.idToken) {
          let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
          )
          
          // save the tokens locally
          localStorage.setItem('access_token', authResult.accessToken);
          localStorage.setItem('id_token', authResult.idToken);
          localStorage.setItem('expires_at', expiresAt);  

          // calling start api
        var token = localStorage.getItem('access_token');
       
        console.log(process.env.VUE_APP_AUTH0_CONFIG_DOMAIN);
        axios.get('https://' + process.env.VUE_APP_AUTH0_CONFIG_DOMAIN + '/userinfo', { headers: { "Authorization": `Bearer ${token}` } })
          .then((authResponse) => {
          
            console.log(authResponse)
            // axios.get(config.baseUrl+'/api/start?username=' + authResponse.data.email)
            //   .then((response) => {
            //     // console.log(response.data);
            //     context.state.email = response.data.data.user?response.data.data.user.username:null;
            //     context.state.companyName = response.data.data.company ? response.data.data.company.name : null;
            //     context.state.userId = response.data.data.user?response.data.data.user.id:null;
            //     context.state.roles = response.data.data.roles?response.data.data.roles:[];
            //     context.state.companyId =  response.data.data.company ? response.data.data.company.id : null;
            //     context.state.avatarUrl= response.data.data.avatarUrl? response.data.data.avatarUrl: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1';
            //   }).catch((error) => {
            //     console.log(error);
            //     alert('login failed. Error startApi'+error);
            //     router.replace('/logout');
            //   });
          }).catch((authError) => {
            console.log('login failed. Error getUserInfoFromAuth'+ authError);
            router.replace('/logout');
          });
          
          console.log('succesfully loged in');
          router.replace('/')
        } 
        else if (err) {
          alert('login failed. Error auth0HandleAuthentication');
          router.replace('/login');
          console.log(err);
        }
      })
    },
    auth0Logout () {
      // No need to update the bearer in global axiosConfig to null because we are redirecting out of the application
      // Clear Access Token and ID Token from local storage
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');

      // setting all the 
      // redirect to auth0 logout to completely log the user out
      window.location.href = process.env.VUE_APP_AUTH0_CONFIG_DOMAINURL + "/v2/logout?returnTo=" + window.location.origin + "/login&client_id=" + process.env.VUE_APP_AUTH0_CONFIG_CLIENTID; 
    },
    resetState (state) {
      //reseting all the values
      Object.assign(state, getDefaultValues())
    }   
  }
})

import { createWebHistory, createRouter } from "vue-router";
import Home from "./view/Home.vue";
import About from "./view/About.vue";
import ContactUs from "./view/ContactUs.vue";
import Members from "./view/Members.vue";
import Auth0Callback from "./view/Auth0Callback.vue";
import Login from "./view/Login.vue";
import Store from './store';
import exportExcel from "./view/ExportExcel.vue";
const routes = [
  {
    path: '/auth0callback',
    name: 'auth0callback',
    component: Auth0Callback,
  },
  {
    path: "/",
    name: "Home",
    component: exportExcel,
    meta: { requiresAuth: true }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactUs,
  },
  {
    path: '/members',
    name: 'members',
    component: Members,
    meta: { requiresAuth: true }
  },{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Home
  },
  {
    path: '/Export-Excel',
    name: 'exportExcel',
    component: exportExcel,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach( (to,from,next)=>{
  // loggin out
  if(to.matched.some(record=>record.path == "/logout")){
    console.log('logging out')
    Store.dispatch('auth0Logout');
    Store.dispatch('resetState');
    
  }

  // Allow finishing callback url for logging in
  if(to.matched.some(record=>record.path == "/auth0callback")){
    console.log('auth0 callback handling')
   Store.dispatch('auth0HandleAuthentication');
  
 }
 
  // check if user is logged in (start assuming the user is not logged in = false)
  let routerAuthCheck = false;  
  // Verify all the proper access variables are present for proper authorization
  if( localStorage.getItem('access_token') && localStorage.getItem('id_token') && localStorage.getItem('expires_at')){
    // alert('found local storage tokens');
    

    // Check whether the current time is past the Access Token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // set localAuthTokenCheck true if unexpired / false if expired
    routerAuthCheck = new Date().getTime() < expiresAt;  
  }
 
   // set global ui understanding of authentication
   Store.commit('setUserIsAuthenticated', routerAuthCheck); 
 
   // check if the route to be accessed requires authorizaton
   if (to.matched.some(record => record.meta.requiresAuth)) {
     // Check if user is Authenticated
     if(routerAuthCheck){
       // user is Authenticated - allow access
       next();
     }
     else{
       // user is not authenticated - redirect to login
       router.replace('/login');
     } 
   }
   // Allow page to load 
   else{
     next();
   }
 });
 
export default router;
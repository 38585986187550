<template lang="">
    <div class="container row px-lg-5 mx-auto my-3 contactus">
        <div class="col-md my-3"><ContactForm/></div>
        <div class="col-md my-3"><ContactCard/></div>
    </div>
</template>
<script>
import ContactCard from '@/components/ContactCard.vue';
import ContactForm from '@/components/ContactForm.vue';


export default {
    components:{
        ContactForm,
        ContactCard
    }
}
</script>
<style lang="scss">
    @media screen and (max-width: 766px) {
        .contactus{
            flex-direction: column-reverse;
        }
}
</style>
<template>
  <NavBar />
  <!-- <SideNav/> -->
  <router-view />
</template>
  
<script>

import NavBar from './components/NavBar.vue'
import axios from 'axios';
import router from './router';
// import { config } from 'process';
import config from './config';

// import SideNav from './components/SideNav.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    // SideNav,
  },
  beforeCreate() {
    config.baseUrl= window.location.origin; 
    if (localStorage.getItem('access_token') && localStorage.getItem('id_token') && localStorage.getItem('expires_at')) {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    // set localAuthTokenCheck true if unexpired / false if expired
      if ( new Date().getTime() < expiresAt ) {
        var token = localStorage.getItem('access_token');
        console.log(token);
        console.log(process.env.VUE_APP_AUTH0_CONFIG_DOMAIN);
        axios.get('https://' + process.env.VUE_APP_AUTH0_CONFIG_DOMAIN + '/userinfo', { headers: { "Authorization": `Bearer ${token}` } })
          .then((authResponse) => {
            console.log(authResponse)
            // axios.get(config.baseUrl+'/api/start?username=' + authResponse.data.email)
            //   .then((response) => {
            //     console.log(response);
            //     this.$store.commit('setEmail',null);
            //     this.$store.commit('setCompanyName','smallwood');
            //     // this.$store.commit('setUserId',response.data.data.user?response.data.data.user.id:null);
            //     // this.$store.commit('setRoles',response.data.data.roles?response.data.data.roles:[]);
            //     // this.$store.commit('setComanyId',response.data.data.company ? response.data.data.company.id : null);
            //     // this.$store.commit('setAvatar',response.data.data.avatarUrl? response.data.data.avatarUrl: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
                
            //   }).catch((error) => {
            //     console.log(error);
            //     alert('login failed. Error startApi'+error);
            //     router.replace('/logout');
            //   });
          }).catch((authError) => {
            console.log('login failed. Error getUserInfoFromAuth'+ authError);
            router.replace('/logout');
          });
      }
    }
  },
}
</script>
  
<style lang="scss">
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.2.0/css/bootstrap.min.css");
:root {
	--primary: #4ade80;
	--primary-alt: #22c55e;
	--grey: #64748b;
	--dark: #1e293b;
	--dark-alt: #334155;
	--light: #f1f5f9;
	--sidebar-width: 300px;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Fira sans', sans-serif;
}

// button {
// 	cursor: pointer;
// 	appearance: none;
// 	border: none;
// 	outline: none;
// 	background: none;
// }
#app {
  // display: flex;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // margin-top: 60px;

}
</style>
  
<template lang="">

    <form class="shadow-xl p-3 p-lg-5 p-md-4 contact-form">
            <h4>Contact Form</h4>
            <div class="mb-3 ">
                <label for="name" class="form-label">Full Name</label>
                <input type="text" id="name" class="form-control border border-dark-light p-2 border-opacity-20 bg-light" placeholder="Your name">
            </div>
            <div class="mb-3">
                <label for="email" class="form-label">Email</label>               
                <input type="text" id="email" class="form-control border border-dark-light p-2 border-opacity-20 bg-light" placeholder="Your email">
            </div>
            <div class="mb-3">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="marketing" >
                    <label class="form-check-label" for="marketing">
                       Recieve Marketing email
                    </label>
                </div>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
    </form>

</template>

<script>
export default {

}
</script>

<style lang="scss">
.shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.contact-form{
    border-radius: 10px;
    input{   
        // background-color: rgb(246 247 248 / 1);
        font-size: small;
    }
    label{
        font-size: small;
    }
    button{
        font-size: small;
    }
}
.border-dark-light {
    border-color: rgb(211, 215, 221);
}
</style>

import config from '@/config';
import axios from 'axios';
// import FileSaver from 'file-saver'
export default{
    data(){
        return {
            families : [],
            loader:false,
        }
    },
    created() {
    },
    methods: {
        getFamilies(){
            axios.get(config.baseUrl+'/api/families')
            .then((response)=>{
                if(response && response.data && response.data.families){
                    this.families = response.data.families;
                }
            }).catch((error)=>{            
                console.log(error);  
            }); 
        },
        exportExcel(familyId){
            this.loader = true;
            axios.get(config.baseUrl+'/api/smallwood/excel/generate?familyId='+familyId, 
            // {
            //     file_name: "smallwood"
            // }, 
            {
                responseType: 'blob'
            })
            .then((response)=>{
                const url = URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                'download',
                `smallwood.xlsm`
                )
                document.body.appendChild(link)
                link.click()

                // FileSaver.saveAs(response.data, "smallwood.xlsm");



                // const link = document.createElement('a');
                // link.href = 'data:application/octet-stream;charset=utf-8;base64,' + response.data;
                // link.target = '_blank';
                // link.download = 'smallwood.xlsm';
                // link.click();
                this.loader = false;
            }).catch((error)=>{
                console.log(error);            
                alert("contact record not found") 
                this.loader = false;
            });
        }
    },
}
<template >
    <div>
        
    <SliderComponent/>
    <ContactUs/>
    </div>
</template>
<script>
import SliderComponent from '../components/Slider.vue'
import ContactUs from './ContactUs.vue';
export default {
    components: {
      SliderComponent,
      ContactUs
    }
}
</script>
<style lang="">
    
</style>
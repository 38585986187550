<template lang="">
<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" href="#">SmallWood</a>
        <!-- <a class="navbar-brand" href="index.html"><img src="assets/img/logo.svg" height="31" alt="logo"></a> -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"> </span>
        </button>
        <div class="collapse navbar-collapse border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
                <router-link to="/"><li class="nav-item"><a class="nav-link" aria-current="page" >Home</a></li></router-link>
                <router-link to="/about"><li class="nav-item"><a class="nav-link" aria-current="page">About</a></li></router-link>
                <router-link to="/contact"><li class="nav-item"><a class="nav-link" aria-current="page" >Contact</a></li></router-link>
                <router-link to="/members"><li class="nav-item my-auto"><a class="nav-link" aria-current="page" >
                   <span v-if="this.$store.state.avatarUrl!=''"> <img   v-bind:src=this.$store.state.avatarUrl width="24" height="24" alt="" /></span>
                    <span v-if="this.$store.state.avatarUrl==''">Info</span>    
                </a></li></router-link>
                <!-- <router-link to="/Export-Excel"><li class="nav-item"><a class="nav-link" aria-current="page" >Families</a></li></router-link> -->
            </ul>
            <div class="d-flex ms-lg-4" v-if="!this.$store.state.userIsAuthorized">
                <router-link to="/members"><a class="btn btn-secondary-outline" href="#!">Sign In</a></router-link>
             </div>
            <div class="d-flex ms-lg-4" v-if="this.$store.state.userIsAuthorized">
                <router-link to="/logout"><a  class="btn btn-warning ms-3" href="#!">Log Out</a></router-link>
            </div>
        </div>
    </div>
</nav>

</template>

<script>
export default {

}
</script>

<style lang="">

</style>

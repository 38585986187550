import {createApp} from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import pmodal from './components/p-modal.vue'
import 'bootstrap/dist/css/bootstrap.css'
createApp(App).component("p-modal", pmodal).use(store).use(router).mount('#app')

import 'bootstrap/dist/js/bootstrap.js'

// import axios from 'axios'

<template>
  <transition name="fade" v-if="showModal" appear>
    <div  :style="themeStyle" class="modal-mask">
      <div
        class="modal-wrapper"
        @click.self="hide"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" :class="size">
          <transition name="slide" v-show="showModal" appear>
            <div class="modal-content">
              <header class="modal-header" id="modalTitle">
                <slot name="header" v-if="hidetitle == false">
                </slot>
                <button
                  type="button"
                  class="btn-close"
                  @click="hide"
                  aria-label="Close modal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </header>
              <section class="modal-body" id="modalDescription">
                <slot name="body"></slot>
              </section>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    size: {
      type: String,
      default: "medium",
      description: "Modal size",
    },
    hidetitle: {
      type: Boolean,
      default: false,
      description: "Hide modal title",
    },
  },
  data() {
    return {
      sizeModal: '49%',
      showModal: false,
    };
  },
  methods: {
    hide() {
      this.showModal = false;
    },
    onEscapeKeyUp(event) {
      if (event.which === 27) {
        this.hide();
      }
    },
    show() {
      this.showModal = true;
      this.$forceUpdate();
    },
  },
  computed: {
    themeStyle() {
      return {
        "--modal-size": this.sizeModal,
        "--min-width": "576px"
      };
    },
  },
  mounted() {
    if (this.size == 'small'){
      this.sizeModal = "30%"
    }
    if (this.size == 'medium'){
      this.sizeModal = "49%"
    }
    if (this.size == 'large'){
      this.sizeModal = "70%"
    }
    window.addEventListener("keyup", this.onEscapeKeyUp);
    console.log(this.sizeModal);
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: top;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.5;
  color: #32325d;
}

.modal-content {
  overflow-y: auto;
  max-height: 95vh;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.form-control-label {
  color: #525f7f;
  font-size: 0.875rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
  top: 5%;
  transform: translateY(-50%);
}

 @media (min-width: 576px) {
  .modal-dialog {
    background-color: white;
    max-width: var(--modal-size);
    margin: 1.75rem auto;
  }
} 
</style>
<template lang="">
<div>
    <p>User Name: {{this.$store.state.email}}</p>
    <p>User Id: {{this.$store.state.userId}}</p>
    <p>Company Name: {{this.$store.state.companyName}}</p>
    <p>Company Id: {{this.$store.state.companyId}}</p>
    <p>Roles: {{this.$store.state.roles}}</p>
    <p>Avatar Url: {{this.$store.state.avatarUrl}}</p>
</div>
<!-- <Modal @close="toggleModal" :modalActive="modalActive">
   
        <h1>This is a Modal Header</h1>
        <p>This is a modal message</p>

</Modal> -->
<!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button> -->








<!-- ------------------P modal -->
<!-- <button @click="toggleModal" type="button">Open Modal</button>
<p-modal ref="account-modal" :size="'small'">
    <template v-slot:header> Account</template>
    <template v-slot:body>Hey </template>
</p-modal>
<button
                id="show-btn"
                @click="showaccountModal"
                class="btn btn-primary btn-md"
              >
                Add
              </button> -->
              
<!-- ------------------P modal  End-->




<!-- ------------------B modal -->
<!-- <BModal ref="thisModal">
    <template #body>This should be in the body</template>
</BModal> -->
<!-- <button @click="showModal">Show Modal</button> -->
</template>

<script>
// import Modal from '@/components/Modal.vue';

// import BModal from "@/components/b-modal.vue";
import { ref } from "vue";

export default {
    data() {
        return {

        }
    },
    components: {
        // Modal,
        // BModal,
    },
    setup() {
        const modalActive = ref(false);
        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        };
        return {
            modalActive,
            toggleModal
        };
    },
    methods: {
        showaccountModal() {
      this.$refs["account-modal"].show();
    },
     showModal(){
        this.$refs["thisModal"].show();
        
}
    },
}
</script>

<style lang="">

</style>

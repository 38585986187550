<template>
  <h1>
    finalizing authentication...
  </h1>
</template>

<script>
// import axios from 'axios';
  export default {
    name: 'Auth0Callback',
    data () {
      return {
          
      }
    },
    beforeMount() {
      this.getUserInfo();
    },
    methods: {
      async getUserInfo() {
        console.log("inside getUserInfo");
   
      }
    },
  }
</script>

<style>

</style>

<template>
<!-- <button v-on:click="auth0Login()">Login</button> -->
<!-- {{this.$store.state.avatarUrl}} -->

<div class="p-4">
    <h1>Logging in</h1>    
    
</div>
</template>
<script>
export default {
    name: 'Login',
    created() {
        this.auth0Login();
    },
    methods: {
        auth0Login(){
            this.$store.dispatch('auth0Login');

            console.log('we are in auth0Login');
        },
    },
    }
</script>
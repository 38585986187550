<template lang="">
<div class="row pt-4">
    <div class="col-md-8 offset-md-2">
        <div class="card border-0">
            <!-- title -->
            <div class="d-md-flex align-items-center">
                <div>
                    <h4 class="card-title font-16 text-uppercase">
                        Families
                    </h4>
                </div>
            </div>
            <div class="card-body ">
                <div class="d-flex justify-content-center" v-if="loader">
                    <div class="spinner-border mt-4 mb-4 text-primary" role="status">
                        <span class="sr-only" id="loading">Loading</span>
                    </div>
                    <div class="align-self-center ml-2">
                        <span>Loading</span>
                    </div>
                </div>
                <div v-if="!loader">
                    <table class="table ">
                        <thead>
                            <tr>
                                <th scope="col ">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value,index) in families" :key="value.id">
                                <th scope="row">{{((currentPage-1)*50)+index+1}}</th>
                                <td>{{value.name}}</td>
                                <td><button class="btn btn-primary" v-on:click="exportExcel(value.id)">Export</button></td>
                            </tr>

                        </tbody>
                    </table>
                    <div class="container-fluid">
                        <pagination
                        :total-pages="total_pages"
                        :current-page="currentPage"
                        @pagechanged="onPageChange"
                        />
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import familyService from '@/mixins/family-service';
import config from '@/config';
import axios from 'axios';
import pagination from '@/components/Pagination.vue';

export default {
     data(){
        return {
            families : [],
            page : 1,
            total_pages : 0,
            currentPage: 1,
        }
    },
    components:{
        pagination
    },
    mixins: [familyService],
    created() {
        this.getFamilies();
    },
    computed:{
        
    },
    methods:{
        getFamilies(){
            this.loader= true;
            axios.get(config.baseUrl+'/api/families?page='+this.currentPage)
            .then((response)=>{
                if(response && response.data && response.data.families){
                    this.families = response.data.families;
                    this.total_pages = response.data.meta.total_pages;
                    this.loader= false;
                }
            }).catch((error)=>{
                console.log(error); 
                this.loader=false;
            }); 
        },
        onPageChange(page) {
            this.currentPage = page;
            this.getFamilies();
        }
    }
}
</script>

<style lang="">

</style>
